import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { navigateTo, redirectTo } from '../../../effects/routing';
import {
  NEW_PROPERTY,
  UPLOAD_CSV,
  UPLOAD_CSV_FINAL,
} from '../../../constants/routes';
import { CLOSE_DIALOG } from '../../../events/dialogs';
import { FINAL_STEP_ORDER, GROUP_CHILDRENS } from './constants';
import { analytics } from '../../../effects/analytics';
import { addItem } from '../../../utils/formDataUtils';

export const CSV_FILE_UPLOADED = 'CSV_FILE_UPLOADED';
export const CSV_FILE_MAPPING_CHOICES = 'CSV_FILE_MAPPING_CHOICES';
export const CSV_FILE_MAPPING_CHOICES_LOADED =
  'CSV_FILE_MAPPING_CHOICES_LOADED';
export const CSV_FILE_ERRORS = 'CSV_FILE_ERRORS';
export const CSV_CHANGE_STEP = 'CSV_CHANGE_STEP';
export const CSV_CHANGE_HEADER_VALUES = 'CSV_CHANGE_HEADER_VALUES';
export const CSV_CHANGE_CHOICES_VALUES = 'CSV_CHANGE_CHOICES_VALUES';
export const CSV_SEND_MAPPING_TO_BACKEND = 'CSV_SEND_MAPPING_TO_BACKEND';
export const CSV_MAPPING_SENT = 'CSV_MAPPING_SENT';
export const CSV_INITIAL_FIELDS_MAPPING = 'CSV_INITIAL_FIELDS_MAPPING';
export const CSV_VALUES_FIELDS_MAPPING = 'CSV_VALUES_FIELDS_MAPPING';
export const CSV_PAGE_UNMOUNTED = 'CSV_PAGE_UNMOUNTED';
export const CSV_NEXT_STEP = 'CSV_NEXT_STEP';
export const CSV_PREVIOUS_STEP = 'CSV_PREVIOUS_STEP';
export const CSV_SELECTED_VALUES_SETTER = 'CSV_SELECTED_VALUES_SETTER';
export const CSV_PUBLISH_MANUALLY = 'CSV_PUBLISH_MANUALLY';

registerEventHandler(
  CSV_FILE_UPLOADED,
  (
    { environment: { apiUrl } },
    { data, headers, errors, file, delimiter, fileEncoding }
  ) => {
    if (errors?.length > 0) {
      return state.set({ 'csv.uploadErrors': true });
    }

    const buildHeaders = buildHeadersObjects(headers);
    const cleanHeaders = filterHeaders(buildHeaders);

    return {
      ...effects.dispatchMany([CSV_FILE_MAPPING_CHOICES, CLOSE_DIALOG]),
      ...navigateTo(UPLOAD_CSV),
      ...state.set({
        'csv.step': 2,
        'csv.rows': data,
        'csv.headers': cleanHeaders,
        'csv.uploadErrors': false,
        'csv.file': file,
        'csv.fileEncoding': fileEncoding,
        'csv.delimiter': delimiter,
      }),
      ...analytics.trackClick('upload-csv-file-uploaded', 'upload-csv-section'),
      ...http.post({
        url: `${apiUrl}/csv-import/upload-file`,
        body: addItem('csvFile', file),
      }),
    };
  },
  [environment()]
);

function buildHeadersObjects(headers) {
  return headers.map((header, index) => ({
    value: header,
    position: index,
  }));
}

function filterHeaders(headers) {
  return headers.filter(
    ({ value }) => value && value.trim() !== '' && !value.includes('undefined')
  );
}

registerEventHandler(
  CSV_FILE_MAPPING_CHOICES,
  ({ environment: { apiUrl }, state: { countryCode } }) => ({
    ...http.get({
      url: `${apiUrl}/csv-import/mapping-choices?countryCode=${countryCode}`,
      successEvent: { id: CSV_FILE_MAPPING_CHOICES_LOADED },
    }),
  }),
  [environment(), state.get({ countryCode: 'publisher.countryCode' })]
);

registerEventHandler(CSV_FILE_MAPPING_CHOICES_LOADED, (_, [{ data }]) => {
  const parsedData = Object.entries(data).map(([key, value]) => ({
    fieldName: key,
    fieldOptions: value,
  }));
  return state.set({
    'csv.choices': parsedData,
  });
});

registerEventHandler(CSV_FILE_ERRORS, (_, payload) =>
  state.set({ 'csv.errors': payload })
);
registerEventHandler(CSV_CHANGE_STEP, (_, { step }) => ({
  ...state.set({ 'csv.step': step }),
}));
registerEventHandler(CSV_CHANGE_HEADER_VALUES, (_, { name, value }) =>
  state.set({
    [`csv.headerValues.${name}`]: value,
  })
);

registerEventHandler(
  CSV_CHANGE_CHOICES_VALUES,
  (_, { name, value, fieldNameToMap }) =>
    state.set({
      [`csv.rowValues.${fieldNameToMap}.${name}`]: value,
    })
);

registerEventHandler(CSV_MAPPING_SENT, () => navigateTo(UPLOAD_CSV_FINAL));

registerEventHandler(
  CSV_SEND_MAPPING_TO_BACKEND,
  ({ environment: { apiUrl }, state: { csvFile, fields, delimiter } }) => {
    const formData = new FormData();
    formData.append('csvFile', csvFile);
    formData.append('fields', JSON.stringify(fields));
    formData.append('delimiter', delimiter);

    return {
      ...http.post({
        url: `${apiUrl}/csv-import/map-file`,
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      ...effects.dispatch({ id: CSV_MAPPING_SENT }),
      ...analytics.trackClick(
        'upload-csv-send-csv-backend',
        'upload-csv-section'
      ),
      ...state.set({
        'csv:hasBeenUploaded': true,
      }),
    };
  },
  [
    environment(),
    state.get({
      csvFile: 'csv.file',
      delimiter: 'csv.delimiter',
      fields: 'csv.finalMappings',
    }),
  ]
);

registerEventHandler(
  CSV_INITIAL_FIELDS_MAPPING,
  ({ state: { headerValues } }) => {
    let parsedHeaders = {};
    Object.entries(headerValues).forEach(([key, value]) => {
      parsedHeaders = {
        ...parsedHeaders,
        [key]: {
          positionInCSV: value,
          choices: null,
        },
      };
    });

    return state.set({
      'csv.finalMappings': parsedHeaders,
    });
  },
  [
    state.get({
      headerValues: 'csv.headerValues',
    }),
  ]
);

registerEventHandler(
  CSV_VALUES_FIELDS_MAPPING,
  ({ state: { rowValues, finalMappings } }, { fieldName, delimiter }) => {
    const updatedMappings = Object.entries(finalMappings).reduce(
      (acc, [key, value]) => {
        if (key === fieldName) {
          acc[key] = {
            ...value,
            choices: rowValues[fieldName] ?? [],
            delimiter,
          };
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    return state.set({
      'csv.finalMappings': updatedMappings,
    });
  },
  [
    state.get({
      rowValues: 'csv.rowValues',
      finalMappings: 'csv.finalMappings',
    }),
  ]
);

registerEventHandler(
  CSV_SELECTED_VALUES_SETTER,
  (_, { name, value, fieldNameToMap }) =>
    state.set({
      [`csv.selectedValues.${fieldNameToMap}.${name}`]: value,
    })
);

registerEventHandler(CSV_PAGE_UNMOUNTED, () =>
  state.set({
    csv: {},
  })
);

registerEventHandler(
  CSV_PREVIOUS_STEP,
  ({ state: { step, headerValues, rowValues, selectedValues } }) => {
    const previousStep = getPreviousStep(step, headerValues);
    const currentField = getFieldNameByStep()[step];
    const filterRowValues = rowValues;
    if (filterRowValues !== undefined) {
      if (currentField in filterRowValues) {
        delete filterRowValues[currentField];
        delete selectedValues[currentField];
      }
    }

    return {
      ...state.set({
        'csv.step': previousStep,
        'csv.rowValues': filterRowValues,
        'csv.selectedValues': selectedValues,
      }),
      ...analytics.trackClick('upload-csv-change-step', 'upload-csv-section', {
        fromStep: step,
        toStep: previousStep,
      }),
    };
  },
  [
    state.get({
      step: 'csv.step',
      headerValues: 'csv.headerValues',
      rowValues: 'csv.rowValues',
      selectedValues: 'csv.selectedValues',
    }),
  ]
);

registerEventHandler(
  CSV_NEXT_STEP,
  ({
    state: {
      step,
      errors,
      headerValues,
      choices,
      finalMappings,
      headers,
      rows,
      rowValues,
    },
  }) => {
    if (step < 2) {
      return {};
    }
    let rowValuesByStep = choices;
    const headerIdByStep = getHeaderIdByStep();
    const fieldNameByStep = getFieldNameByStep();
    const errorKeyByStep = headerIdByStep[step] ?? 'selectHeaders';

    if (step !== 2) {
      rowValuesByStep = getRowValuesByHeaderPosition(
        fieldNameByStep[step],
        headerValues,
        headers,
        rows
      );
    }

    const newErrors = validateErrors(
      fieldNameByStep[step],
      rowValuesByStep,
      rowValues,
      headerValues
    );

    if (newErrors.length > 0) {
      return {
        ...effects.dispatch({
          id: CSV_FILE_ERRORS,
          payload: { ...errors, [errorKeyByStep]: newErrors },
        }),
      };
    }
    const extraEvents = [];
    let fieldMappingEvent = { id: CSV_INITIAL_FIELDS_MAPPING };
    let delimiter = '';
    if (step > 2) {
      if (
        fieldNameByStep[step] === 'amenities' ||
        fieldNameByStep[step] === 'rules' ||
        fieldNameByStep[step] === 'nearbyLocations' ||
        fieldNameByStep[step] === 'contactEmails'
      ) {
        delimiter = ',';
      }

      fieldMappingEvent = {
        id: CSV_VALUES_FIELDS_MAPPING,
        payload: { fieldName: fieldNameByStep[step], delimiter },
      };
    }

    const nextStep = getNextStep(
      step,
      headerValues,
      finalMappings ?? {},
      fieldNameByStep
    );
    const tracking = analytics.trackClick(
      'upload-csv-change-step',
      'upload-csv-section',
      {
        fromStep: step,
        toStep: nextStep,
      }
    );
    extraEvents.push(fieldMappingEvent);
    if (nextStep === FINAL_STEP_ORDER) {
      extraEvents.push({ id: CSV_SEND_MAPPING_TO_BACKEND });
    }

    return {
      ...effects.dispatchMany([
        {
          id: CSV_FILE_ERRORS,
          payload: { ...errors, [errorKeyByStep]: newErrors },
        },
        ...extraEvents,
      ]),
      ...state.set({ 'csv.step': nextStep }),
      ...tracking,
    };
  },
  [
    state.get({
      step: 'csv.step',
      errors: 'csv.errors',
      choices: 'csv.choices',
      headerValues: 'csv.headerValues',
      finalMappings: 'csv.finalMappings',
      headers: 'csv.headers',
      rows: 'csv.rows',
      rowValues: 'csv.rowValues',
    }),
  ]
);

registerEventHandler(CSV_PUBLISH_MANUALLY, () => ({
  ...effects.dispatch(CLOSE_DIALOG),
  ...analytics.trackClick(
    'modal-upload-csv-manual-button-clicked',
    'upload-csv-section'
  ),
  ...redirectTo(NEW_PROPERTY),
}));

function validateErrors(stepValueName, stepRowValues, rowValues, headerValues) {
  const newErrors = [];

  stepRowValues.forEach(({ fieldName, fieldOptions }) => {
    const checkRows =
      rowValues === undefined || rowValues === []
        ? rowValues
        : rowValues[stepValueName];
    const checkArray = stepValueName === undefined ? headerValues : checkRows;
    if (
      fieldOptions.required === true &&
      (!checkArray ||
        checkArray[fieldName] === null ||
        checkArray[fieldName] === undefined)
    ) {
      newErrors.push(fieldName);
    }
  });
  return newErrors;
}

function getNextStep(currentStep, headerValues, finalMapping, fieldNames) {
  let nextStep = currentStep + 1;

  let isBreak = false;

  if (currentStep >= 2) {
    Object.entries(fieldNames).forEach(([step, field]) => {
      if (headerValues?.[field] !== undefined && step > currentStep) {
        if (!isBreak) {
          nextStep = parseInt(step, 10);
          isBreak = true;
        }
      }
    });
  }
  if (currentStep >= 2 && !isBreak) {
    return FINAL_STEP_ORDER;
  }

  return nextStep;
}

function getRowValuesByHeaderPosition(
  fieldName,
  headerValues,
  headers,
  rowValues
) {
  if (headerValues === undefined) return [];
  const valuePosition = headerValues[fieldName];
  if (valuePosition === undefined) return [];

  const foundHeader = headers.find(
    ({ position }) => position === valuePosition
  );
  if (!foundHeader) return [];
  const rowKey = foundHeader.value;

  let allValues;
  let required = false;
  if (
    fieldName === 'amenities' ||
    fieldName === 'rules' ||
    fieldName === 'nearbyLocations'
  ) {
    allValues = rowValues.flatMap((row) => {
      if (row[rowKey]) {
        return row[rowKey].split(',').map((value) => value.trim());
      }
      return [];
    });
  } else {
    required = true;
    allValues = rowValues.reduce((acc, row) => {
      if (row[rowKey]) acc.push(row[rowKey]);
      return acc;
    }, []);
  }

  return [...new Set(allValues)]
    .filter((value) => value !== '')
    .map((value) => ({
      fieldName: value,
      fieldOptions: {
        required,
        tag: value,
      },
    }));
}

function getHeaderIdByStep() {
  return GROUP_CHILDRENS.reduce((acc, { id, order }) => {
    acc[order] = id;
    return acc;
  }, {});
}

function getFieldNameByStep() {
  return GROUP_CHILDRENS.reduce((acc, { fieldName, order }) => {
    acc[order] = fieldName;
    return acc;
  }, {});
}

function getPreviousStep(currentStep, headerValues) {
  let previousStep = currentStep - 1;

  let isBreak = false;

  if (currentStep >= 2) {
    const steps = Object.entries(getFieldNameByStep());
    steps.reverse();

    steps.forEach(([step, field]) => {
      if (headerValues?.[field] !== undefined && step < currentStep) {
        if (!isBreak) {
          previousStep = parseInt(step, 10);
          isBreak = true;
        }
      }
    });
  }
  if (previousStep === 3) {
    previousStep = 2;
  }
  return previousStep;
}
