import { registerEffectHandler } from 'reffects';
import { isBackofficeUserSelector } from '../selectors/user';
import { isPublisherForTestingSelector } from '../selectors/publisher';

export default function registerAnalyticsEffects(
  analytics,
  store,
  log = console.log
) {
  registerEffectHandler(
    'analytics.track',
    triggerForNotAdminsNorTestUsers(({ name, section, type, payload }) => {
      const { locale, user, publisher } = store.getState();

      analytics.track({
        name: `web_${name}`,
        type,
        section,
        userId: user?.id,
        publisherId: publisher?.id,
        subscriptionId: publisher?.subscriptionId,
        countryCode: publisher?.countryCode,
        locale,
        source: 'web',
        payload,
        ...payload,
      });
    })
  );

  registerEffectHandler(
    'analytics.page',
    triggerForNotAdminsNorTestUsers(({ name, payload }) => {
      const { locale, user, publisher } = store.getState();

      analytics.page({
        name: `web_${name}`,
        userId: user?.id,
        publisherId: publisher?.id,
        subscriptionId: publisher?.subscriptionId,
        countryCode: publisher?.countryCode,
        locale,
        source: 'web',
        payload,
        ...payload,
      });
    })
  );

  function triggerForNotAdminsNorTestUsers(f) {
    return (params) => {
      const state = store.getState();
      const isBackofficeUser = isBackofficeUserSelector(state);
      const isPublisherForTesting = isPublisherForTestingSelector(state);

      if (isPublisherForTesting) {
        log('Not tracked because the publisher is for testing.');
        return;
      }

      if (isBackofficeUser) {
        log('Not tracked because the user is from backoffice.');
        return;
      }
      f(params);
    };
  }
}

export const analytics = {
  trackClick(name, section, payload = {}) {
    return {
      'analytics.track': {
        name,
        section,
        type: 'click',
        payload,
      },
    };
  },
  trackImpression(elementName, section, visible) {
    return {
      'analytics.track': {
        name: elementName,
        section,
        type: 'impression',
        payload: { visible },
      },
    };
  },
  trackPageView(name, payload) {
    return {
      'analytics.page': {
        name,
        payload,
      },
    };
  },
};
