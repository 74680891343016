import styled from 'styled-components';
import { useState } from 'react';
import { dispatch } from 'reffects';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import InputTextarea from 'design-system/components/InputTextarea';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { TRACK_CLICK } from '../../../partials/Tracking/events';

const WrapperRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${color.background(COLOR_PALETTE.PUMPKIN_A05)};
  padding: ${spacing.value(SPACING_SIZE.L)} ${spacing.value(SPACING_SIZE.XL)};
  gap: ${spacing.value(SPACING_SIZE.M)};
  bottom: 0;
`;

const WrapperFeedback = styled.div`
  display: flex;
  flex-direction: column;
  ${color.background(COLOR_PALETTE.PUMPKIN_A05)};
  padding: ${spacing.value(SPACING_SIZE.L)} ${spacing.value(SPACING_SIZE.XL)};
  gap: 0;
  bottom: 0;

  ${Subtitle3} {
    margin-bottom: ${spacing.value(SPACING_SIZE.S)};
  }
`;

const Feedback = styled.div`
  display: flex;

  ${Illustration} {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  align-self: end;
`;

const FeedbackTextarea = styled(InputTextarea)`
  div {
    border-radius: ${spacing.value(SPACING_SIZE.S)};
  }
`;

function LeadFeedback({
  eventExtraInformation = {},
  eventIdentifier,
  customTitle,
}) {
  const [selected, setSelected] = useState();
  const [feedbackSent, setFeedbackSent] = useState();

  const onClick = (rating) => {
    setSelected(rating);

    const eventName = eventIdentifier
      ? `lead-${eventIdentifier}-feedback-click`
      : 'lead-feedback-click';
    const payload = {
      rating: rating + 1,
      ...eventExtraInformation,
    };

    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName,
        sectionName: 'lead-detail-feedback',
        payload,
      },
    });
  };

  if (feedbackSent) return null;

  return (
    <>
      {!selected ? (
        <RatingFeedback
          rating={selected}
          onClick={onClick}
          customTitle={customTitle}
        />
      ) : (
        <OpenQuestionFeedback
          rating={selected}
          setFeedbackSent={setFeedbackSent}
          eventIdentifier={eventIdentifier}
        />
      )}
    </>
  );
}

function RatingFeedback({ rating, onClick, customTitle }) {
  const NUMBER_OF_STARTS = 5;
  return (
    <WrapperRating>
      <Subtitle3>
        {customTitle || (
          <FormattedMessageWithValidation id="leadpage_interests_poll_title" />
        )}
      </Subtitle3>
      <Feedback>
        {[...Array(NUMBER_OF_STARTS)].map((e, id) => (
          <Illustration
            key={`start-${id}`}
            name={rating >= id ? 'starFilled' : 'star'}
            onClick={() => onClick(id)}
          />
        ))}
      </Feedback>
    </WrapperRating>
  );
}

function OpenQuestionFeedback({ rating, setFeedbackSent, eventIdentifier }) {
  const [feedbackText, setFeedbackText] = useState();
  const onClick = () => {
    const eventName = eventIdentifier
      ? `lead-${eventIdentifier}-feedback-question-answered`
      : 'lead-feedback-question-answered';
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName,
        sectionName: 'lead-detail-feedback',
        payload: {
          feedbackText,
        },
      },
    });
    setFeedbackSent(true);
  };

  return (
    <WrapperFeedback>
      <Subtitle3>
        <FormattedMessageWithValidation
          id="leadpage_interests_poll_question_title"
          values={{ rating: rating + 1 }}
        />
      </Subtitle3>
      <FeedbackTextarea
        name="message"
        value={feedbackText}
        autoFocus
        onChange={(e) => setFeedbackText(e.target.value)}
      />
      <ButtonWrapper>
        <SecondaryButton
          $size={BUTTON_SIZE.SMALL}
          type="button"
          onClick={onClick}
          disabled={!feedbackText}
        >
          <FormattedMessageWithValidation id="leadpage_interests_poll_question_save_button" />
        </SecondaryButton>
      </ButtonWrapper>
    </WrapperFeedback>
  );
}

export default LeadFeedback;
