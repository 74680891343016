import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { formatNumeral, unformatNumeral } from 'cleave-zen';
import InputBase from 'design-system/components/InputBase/InputBase';
import { useInputBase } from 'design-system/hooks/useInputBase';
import { withStylesAndInnerRef } from 'design-system/utils';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';

export const INPUT_NUMBER_UNIT_POSITION = {
  // eslint-disable-next-line symbol-description
  BEFORE: Symbol(),
  // eslint-disable-next-line symbol-description
  AFTER: Symbol(),
};
export const INPUT_NUMBER_TYPE = {
  INTEGER: Symbol('integer'),
  DECIMAL: Symbol('decimal'),
};

const modUnitPositionRight = () => css`
  > ${InputBase.Box} {
    flex-direction: row;

    ${Subtitle3} {
      text-align: right;
    }
  }
`;

const modUnitPositionLeft = () => css`
  > ${InputBase.Box} {
    flex-direction: row-reverse;

    ${Subtitle3} {
      text-align: left;
    }
  }
`;

const modUnitPosition = (position) =>
  ({
    [INPUT_NUMBER_UNIT_POSITION.BEFORE]: modUnitPositionLeft,
    [INPUT_NUMBER_UNIT_POSITION.AFTER]: modUnitPositionRight,
  }[position]);

const Wrapper = styled(InputBase)`
  > ${InputBase.Box} {
    display: flex;
    align-items: center;
    gap: ${spacing.value(SPACING_SIZE.XS)};

    input {
      ${spacing.inline(SPACING_SIZE.NONE)}
    }

    ${Subtitle3} {
      user-select: none;
      text-align: right;
      max-width: 5ex;
    }
  }

  ${({ unitPosition }) => modUnitPosition(unitPosition)}
`;

function InputNumber({
  unit,
  unitPosition = INPUT_NUMBER_UNIT_POSITION.AFTER,
  innerRef,
  type = INPUT_NUMBER_TYPE.INTEGER,
  onChange,
  maxDecimals,
  value,
  'data-test': dataTest,
  htmlRef,
  ...rest
}) {
  const [inputValue, setInputValue] = useState(undefined);
  const {
    inputBaseProps,
    boxProps: { icon, ...boxProps },
    inputProps,
  } = useInputBase(rest);

  // eslint-disable-next-line no-shadow
  const formatValue = (value) =>
    formatNumeral(value, {
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numeralDecimalScale: type === INPUT_NUMBER_TYPE.INTEGER ? 0 : maxDecimals,
    });

  useEffect(() => {
    if (value !== null && value !== undefined && value !== '') {
      setInputValue(formatValue(String(value)));
    }
  }, [value]);

  return (
    <Wrapper
      unitPosition={unitPosition}
      {...inputBaseProps}
      data-test={dataTest}
    >
      <InputBase.Box {...boxProps}>
        <input
          type="text"
          ref={innerRef}
          value={inputValue}
          onChange={(event) => {
            setInputValue(formatValue(event.target.value));
            onChange({
              target: {
                name: event.target.name,
                rawValue: unformatNumeral(event.target.value),
              },
            });
          }}
          className=".sentry-unmask"
          {...inputProps}
        />
        {unit && (
          <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A40}>{unit}</Subtitle3>
        )}
      </InputBase.Box>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(InputNumber);
